import { getRandomProviderPic } from '@utils/getRandomProviderPic';
import Image from 'next/image';
import { useId, useState } from 'react';

interface Props {
  images: Array<string>;
  title: string;
}

const SearchResultImage: React.FC<Props> = ({ images, title }) => {
  const seed = useId();
  const hasImage = images?.length > 0;
  const fallbackImage = getRandomProviderPic(images?.[0] || title || seed);
  const [src, setSrc] = useState<string>(() =>
    hasImage ? images[0] : fallbackImage
  );

  const onError = () => {
    setSrc(fallbackImage);
  };

  return (
    <Image
      src={src}
      alt={title ?? ''}
      width={275}
      height={275}
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      onError={() => onError()}
    />
  );
};

export default SearchResultImage;
