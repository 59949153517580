import { Icon } from '@chakra-ui/icon';
import {
  Box,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/layout';
import parse from 'html-react-parser';
import { MdStar, MdStarBorder } from 'react-icons/md';
import Button from '@components/Button';
import { getColor } from '@utils/getColor';
import CookieStorage from 'utils/cookieStorage';
import { useModal } from '~/contexts/ModalContext';
import SearchResultImage from './SearchResultImage';
import { CaringStars } from '@components/CaringStars';
import { SearchResultProps } from './SearchResultType';
import { formatNumberLocale, formatRating } from '~/utils/number';
import { MdVerified } from 'react-icons/md';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';
import Description from '@components/ProviderCard/Description';
import { filterNonReactAttributes } from '@utils/filterNonReactAttributes';
import { Heading } from '@chakra-ui/react';
import { pluralize } from '@utils/strings';
import { useContext } from 'react';
import SiteContext from '~/contexts/SiteContext';

interface RatingProps {
  rating: number | string;
  reviewCount: number;
  ratingStarsColor?: string;
  ratingStarsColorRange?: string;
  iconSize?: number;
  countFontSize?: string;
  reviewsFontSize?: string;
  reviewsTextDecoration?: string;
}

export const Rating: React.FC<RatingProps> = ({
  rating,
  reviewCount,
  ratingStarsColor,
  ratingStarsColorRange,
  iconSize = 4,
  countFontSize = 'xs',
  reviewsFontSize = 'xs',
  reviewsTextDecoration = 'none',
}) => {
  const generateRatingIcons = (): JSX.Element[] => {
    return Array.from({ length: 5 }, (_, i) => (
      <Icon
        key={i}
        margin={0}
        as={Math.floor(Number(rating)) > i ? MdStar : MdStarBorder}
        boxSize={iconSize}
        role="presentation"
        style={{ marginInlineStart: 0 }}
        color={getColor(ratingStarsColor, ratingStarsColorRange)}
      />
    ));
  };

  const pluralizedReviewCount = pluralize(reviewCount, 'review');

  return (
    <Box display="flex" alignItems="center">
      <Heading
        as="p"
        mr={1}
        fontWeight="bold"
        fontSize={countFontSize}
        aria-label={`${rating} star rating`}
        color={getColor(ratingStarsColor, ratingStarsColorRange)}
      >
        {rating}
      </Heading>
      {generateRatingIcons()}
      <Text
        color="gray.800"
        fontSize={reviewsFontSize}
        textDecoration={reviewsTextDecoration}
      >
        ({reviewCount} {pluralizedReviewCount})
      </Text>
    </Box>
  );
};

const SearchResult: React.FC<SearchResultProps> = ({
  id,
  legacyId = '',
  images,
  title,
  address,
  reviewCount,
  averageRating,
  price,
  showPrice = false,
  element = 'h2',
  path,
  ratingStarsColor = 'accent',
  ratingStarsColorRange = '500',
  providerTitleColor = 'primary',
  providerTitleColorRange = '600',
  boxShadow = 'lg',
  border = 'none',
  borderColor = '',
  borderColorRange = '',
  caringStars = [],
  displayBadges = false,
  displayLearnMoreButton,
  learnMoreButtonText = 'Learn More',
  displayRequestInfoButton = false,
  requestInfoButtonText = 'Get Pricing',
  modalId = '',
  requestInfoButtonColorScheme,
  learnMoreButtonColorScheme,
  showVerifiedBadge = false,
  queryId = '',
  listId = '',
  description,
  isChecked = false,
  displayCompareOption,
  handleCompare,
  dontOpenInNewTab,
  ...rest
}) => {
  const siteProps = useContext(SiteContext);
  const domain = siteProps.site?.path ?? '';
  const linkProps = filterNonReactAttributes(rest);
  const rating = formatRating(averageRating);
  const { show: showInquiryForm } = useModal(modalId);
  const elementClicked = useElementClicked();
  const openInquiryForm = () => {
    const selectedProvider = { slug: path, id };

    if (CookieStorage.enabled) {
      CookieStorage.set('provider', JSON.stringify(selectedProvider));
    }

    showInquiryForm();
  };

  const hasBadges = caringStars.length > 0 && displayBadges;
  const shouldRenderHelpers =
    displayRequestInfoButton || displayLearnMoreButton || displayBadges;

  return (
    <LinkBox
      maxW="sm"
      as="article"
      border={border}
      borderColor={getColor(borderColor, borderColorRange)}
      {...linkProps}
    >
      <VStack
        p="10px"
        bg="white"
        rounded="md"
        alignItems={'left'}
        boxShadow={boxShadow}
        justifyContent="space-between"
        minHeight={{ base: 'max-content', sm: 'xs', md: '360px' }}
        height="100%"
      >
        <VStack alignItems="left">
          <Box rounded="md">
            <Box
              height="155"
              rounded="md"
              bg="gray.200"
              overflow="hidden"
              position="relative"
            >
              <SearchResultImage images={images} title={title} />
            </Box>
          </Box>
          <LinkOverlay
            href={path}
            color="gray.700"
            _hover={{ textDecoration: 'underline' }}
            onClick={() => {
              elementClicked({
                element: {
                  type: ElementTypes.LINK,
                  action: ElementActions.INTERNAL_LINK,
                  name: ElementNames.PROVIDER_CARD,
                  text: title,
                  color: 'white',
                  textColor:
                    getColor(providerTitleColor, providerTitleColorRange) || '',
                },
                destinationUrl: path,
                query: {
                  locationId: legacyId,
                  queryId: queryId,
                  listId: listId,
                },
              });
            }}
            isExternal={!dontOpenInNewTab}
          >
            <Heading
              size="md"
              as={element}
              color={getColor(providerTitleColor, providerTitleColorRange)}
              display="inline-block"
              width="100%"
            >
              {title}
              {showVerifiedBadge && (
                <Icon
                  as={MdVerified}
                  w="24px"
                  h="24px"
                  role="presentation"
                  color="primary.700"
                  verticalAlign="top"
                  ml="10px"
                  float={{ base: 'right', md: 'none' }}
                />
              )}
            </Heading>
          </LinkOverlay>
          <Text size="sm" color="gray.700">
            {parse(address)}
          </Text>
          {rating && reviewCount > 0 && (
            <Stack direction="row" alignItems="center">
              <Rating
                rating={rating}
                reviewCount={reviewCount}
                ratingStarsColor={ratingStarsColor}
                ratingStarsColorRange={ratingStarsColorRange}
              />
            </Stack>
          )}
        </VStack>
        {price > 0 ? (
          <Heading as="p" size={{ base: 'sm', md: 'md' }}>
            $
            <Text
              as="span"
              size={{ base: 'sm', md: 'md' }}
              filter={showPrice ? '' : 'blur(10px)'}
            >
              {formatNumberLocale(price / 100)}
            </Text>
          </Heading>
        ) : (
          <Text color="gray.600" filter={showPrice ? '' : 'blur(10px)'}>
            Pricing not available
          </Text>
        )}

        {description ? (
          <Description description={description} domain={domain} />
        ) : null}

        {shouldRenderHelpers && (
          <Stack alignItems="center" direction={{ base: 'row' }}>
            <Stack
              mr={3}
              minH="60px"
              alignItems="center"
              direction={{ base: 'row' }}
            >
              {displayRequestInfoButton && (
                <Button
                  h={8}
                  minW={6}
                  size={{ base: 'sm' }}
                  onClick={openInquiryForm}
                  colorScheme={requestInfoButtonColorScheme}
                  elementType={ElementTypes.BUTTON}
                  elementAction={ElementActions.OPEN_MODAL}
                  elementName={ElementNames.INFO_REQUEST_SECTION}
                  destinationUrl={path}
                  query={{
                    locationId: legacyId ?? '',
                    queryId: queryId,
                    listId: listId,
                  }}
                >
                  {requestInfoButtonText}
                </Button>
              )}
              {displayLearnMoreButton && (
                <Button
                  as="a"
                  h={8}
                  minW={6}
                  href={path}
                  variant="outline"
                  size={{ base: 'sm' }}
                  colorScheme={learnMoreButtonColorScheme}
                  elementAction={ElementActions.INTERNAL_LINK}
                  elementName={ElementNames.GENERIC_BUTTON}
                  elementType={ElementTypes.BUTTON}
                  destinationUrl={path}
                  target={dontOpenInNewTab ? '_self' : '_blank'}
                  rel="noopener noreferrer"
                  query={{
                    locationId: legacyId ?? '',
                    queryId: queryId,
                    listId: listId,
                  }}
                >
                  {learnMoreButtonText}
                </Button>
              )}
            </Stack>
            {hasBadges && (
              <CaringStars displayYears={false} caringStars={caringStars} />
            )}
          </Stack>
        )}
      </VStack>
    </LinkBox>
  );
};

export default SearchResult;
