import placeHolder1 from '~/assets/providers/placeholder_1.jpg';
import placeHolder2 from '~/assets/providers/placeholder_2.jpg';
import placeHolder3 from '~/assets/providers/placeholder_3.jpg';
import placeHolder4 from '~/assets/providers/placeholder_4.png';

const placeHolders = [placeHolder1, placeHolder2, placeHolder3, placeHolder4];

export const getRandomProviderPic = (url) => {
  let index = Math.floor(Math.random() * placeHolders.length);
  if (url) {
    let sumIndex = 0;
    try {
      sumIndex = url
        .split('')
        .reduce((sum, char) => sum + char.charCodeAt(0), 0);
    } catch (e) {}
    index = sumIndex % placeHolders.length;
  }

  return placeHolders[index] as unknown as string;
};
