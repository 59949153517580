import { Flex, HStack, Link, Text } from '@chakra-ui/layout';
import { Image, useDisclosure } from '@chakra-ui/react';
import caringStar from '~/assets/badges/caring-star.png';
import { Award } from '~/contexts/Provider';
import { getCaringStarYears, getRecentYears } from '~/utils/caringStars';
import useElementClicked, {
  ElementActions,
  ElementNames,
  ElementTypes,
} from '@components/Analytics/events/ElementClicked';

interface Props {
  years: Award[];
  height?: string;
  alignment?: string;
  yearAlignment?: 'left' | 'center';
  inSidebar?: boolean;
}
const ViewMoreButton: React.FC<{
  fontSize?: string;
  viewAll: boolean;
  onToggle: () => void;
}> = ({ fontSize = 'sm', viewAll, onToggle }) => {
  return (
    <Text
      as="p"
      fontSize={fontSize}
      color="secondary.400"
      fontWeight="bold"
      onClick={(event) => {
        event.preventDefault();
        onToggle();
      }}
    >
      {viewAll ? 'View Less' : 'View More'}
    </Text>
  );
};
const CaringStarsMultiYearBadge: React.FC<Props> = ({
  years,
  height = '150px',
  alignment = 'center',
  yearAlignment = 'center',
  inSidebar = false,
}) => {
  const { isOpen: viewAll, onToggle } = useDisclosure();
  const elementClicked = useElementClicked();
  const allYears = getCaringStarYears(years);
  const totalYears = allYears.length;
  const recentYears = getRecentYears({ years: allYears });
  const maxYears =
    allYears.length > 10
      ? allYears
          .slice(0, 10)
          .reverse()
          .join(', ')
          .concat(`, +${allYears.length - 10} more`)
      : allYears.reverse().join(', ');

  const flexDirection = inSidebar ? 'column' : 'row';
  return (
    <Link
      target="_blank"
      href="/bestseniorliving/"
      _hover={{ textDecoration: 'none' }}
      onClick={(e) => {
        const element = (e.target as HTMLElement).innerHTML;
        const isLinkClick = !['View More', 'View Less'].includes(element);
        elementClicked({
          element: {
            type: isLinkClick ? ElementTypes.LINK : ElementTypes.BUTTON,
            action: isLinkClick
              ? ElementActions.INTERNAL_LINK
              : viewAll
              ? ElementActions.COLLAPSE
              : ElementActions.EXPAND,
            name: ElementNames.CARING_STARS_BADGE,
            text: isLinkClick ? (viewAll ? maxYears : recentYears) : element,
            color: '',
            textColor: isLinkClick ? 'black' : 'secondary.400',
          },
          destinationUrl: '/bestseniorliving/',
        });
      }}
    >
      <Flex
        direction={{ base: flexDirection, lg: 'column' }}
        alignItems={alignment}
        textAlign={{ base: 'left', lg: yearAlignment }}
      >
        <Image
          src={caringStar.src}
          alt="Top rated on Caring.com"
          height={{ base: '95px', lg: height }}
        />
        {inSidebar ? (
          <HStack mt={3}>
            <Text fontSize="xl" fontWeight="bold" maxW="165px">
              {viewAll ? maxYears : recentYears}
            </Text>
            {totalYears > 3 && (
              <ViewMoreButton
                fontSize={'xl'}
                viewAll={viewAll}
                onToggle={onToggle}
              />
            )}
          </HStack>
        ) : (
          <Flex
            direction="column"
            maxW="180px"
            ml={2}
            fontSize={{ base: 'sm', lg: 'md' }}
          >
            <Text
              as="p"
              marginTop={2}
              textDecoration="underline"
              color="gray.700"
            >
              {viewAll ? maxYears : recentYears}
            </Text>
            {totalYears > 3 && (
              <ViewMoreButton viewAll={viewAll} onToggle={onToggle} />
            )}
          </Flex>
        )}
      </Flex>
    </Link>
  );
};

export default CaringStarsMultiYearBadge;
