import { Box, Button, ButtonProps, Link, Text } from '@chakra-ui/react';
import { useState } from 'react';

interface DescriptionProps {
  lastReviewSnippet?: string;
  description?: string;
  readMoreButton?: string;
  domain?: string;
  path?: string;
  openInNewTab?: boolean;
}

const MAX_LENGTH_OF_DESCRIPTION = 140;

const ProviderDescription = ({
  description,
  lastReviewSnippet,
  readMoreButton,
  path,
  colorScheme = 'primary',
  buttonSize = 'xs',
  openInNewTab = false,
}: {
  description?: string;
  lastReviewSnippet?: string;
  readMoreButton?: string;
  path?: string;
  colorScheme?: ButtonProps['colorScheme'];
  buttonSize?: ButtonProps['size'];
  openInNewTab?: boolean;
}) => {
  const [showingMoreDescription, setShowingMoreDescription] = useState(false);
  const cleanedDescription = description?.replace(/(<([^>]+)>)/gi, '') ?? '';

  const shouldReduceDescription =
    cleanedDescription.length > MAX_LENGTH_OF_DESCRIPTION;

  const providerDescription =
    shouldReduceDescription && !showingMoreDescription
      ? cleanedDescription.slice(0, MAX_LENGTH_OF_DESCRIPTION) + '...'
      : cleanedDescription;
  const hasDescription = !!providerDescription || !!lastReviewSnippet;
  if (!hasDescription) {
    return null;
  }
  return (
    <Box w="full">
      <Text
        wordBreak="break-word"
        fontSize="sm"
        className="magnolia-text"
        dangerouslySetInnerHTML={{
          __html: hasDescription
            ? `"${
                !!lastReviewSnippet ? lastReviewSnippet : providerDescription
              }"`
            : '',
        }}
      />

      {readMoreButton === 'redirect_to_provider_page' ? (
        <Button
          as={Link}
          href={path}
          colorScheme={colorScheme}
          size={buttonSize}
          variant="link"
          isExternal={openInNewTab}
        >
          READ MORE
        </Button>
      ) : (
        <>
          {shouldReduceDescription && (
            <Button
              colorScheme={colorScheme}
              size={buttonSize}
              variant="link"
              zIndex={10}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setShowingMoreDescription(!showingMoreDescription);
              }}
            >
              READ {showingMoreDescription ? 'LESS' : 'MORE'}
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

const Description: React.FC<DescriptionProps> = ({
  description,
  lastReviewSnippet,
  readMoreButton,
  path,
  domain,
  openInNewTab,
}) => {
  switch (domain) {
    case 'seniorhomes.com':
      return (
        <ProviderDescription
          description={description}
          readMoreButton={readMoreButton}
          path={path}
          colorScheme="primary"
          buttonSize="xs"
          openInNewTab={openInNewTab}
        />
      );
    case 'caring.com':
      return (
        <ProviderDescription
          description={description}
          lastReviewSnippet={lastReviewSnippet}
          readMoreButton={readMoreButton}
          path={path}
          colorScheme="tertiary"
          buttonSize="sm"
          openInNewTab={openInNewTab}
        />
      );
    default:
      return (
        <ProviderDescription
          description={description}
          readMoreButton={readMoreButton}
          path={path}
          openInNewTab={openInNewTab}
        />
      );
  }
};

export default Description;
