import { Text, VStack, Wrap, WrapItem, WrapProps } from '@chakra-ui/layout';
import { Show } from '@chakra-ui/react';
import Image, { ImageProps } from 'next/image';
import caringStarImage from '~/assets/badges/caring-star.png';
import { Award } from '~/contexts/Provider';

interface Props extends WrapProps {
  caringStars?: Award[];
  displayYears?: boolean;
  height?: ImageProps['height'];
}

const CaringStars: React.FC<Props> = ({
  caringStars = [],
  displayYears = false,
  height = '60',
  ...rest
}) => {
  return (
    <Wrap
      spacing={{ base: 0, lg: 14 }}
      justify={{ base: 'space-around', lg: 'end' }}
      {...rest}
    >
      <WrapItem>
        <VStack spacing={0}>
          <Image
            src={caringStarImage}
            alt={`Top rated on Caring.com`}
            height={height}
          />
          {displayYears && (
            <Show above="lg">
              <Text as="p" fontSize="xl" aria-hidden="true">
                {caringStars.map(({ year }) => year).join(', ')}
              </Text>
            </Show>
          )}
        </VStack>
      </WrapItem>
    </Wrap>
  );
};

export default CaringStars;
