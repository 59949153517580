import { Award } from '~/contexts/Provider';

export const getCaringStarYears = (caringStars: Award[]): number[] => {
  return caringStars?.map((caringStar) => {
    return caringStar.year;
  });
};

export const getRecentYears = ({
  years,
  limit = 3,
}: {
  years: number[];
  limit?: number;
}): string =>
  years
    .sort()
    .reverse()
    .slice(0, limit)
    .reverse()
    .map((year) => year)
    .join(', ');
