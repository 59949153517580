const EMPTY_STRING = '';
const EMPTY_SEARCH_PARAMS = new URLSearchParams();

export const getPathnameFromWindow = (): string => {
  const isWindowUndefined = typeof window === 'undefined';

  return isWindowUndefined ? EMPTY_STRING : window.location.pathname;
};

export const getSearchParamsFromWindow = (): URLSearchParams => {
  const isWindowUndefined = typeof window === 'undefined';

  return isWindowUndefined
    ? EMPTY_SEARCH_PARAMS
    : new URLSearchParams(window.location.search);
};

export const buildPathname = (segments: Array<string>): string | null => {
  const prefix = '/';
  const allSegmentsValid = segments.every(Boolean);

  if (allSegmentsValid) {
    return prefix + segments.join(prefix);
  }

  return null;
};
